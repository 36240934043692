<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import { useAttrs } from 'vue';

defineProps({
    simulation: { type: Object, required: true },
    as: { type: [String, Object, Function], default: 'div' }
});

const { href, ...attrs } = useAttrs();
</script>

<template>
    <div v-bind="attrs" class="flex items-center gap-x-2 p-2 transition-colors ease-in duration-75 hover:bg-primary/10 rounded-xl cursor-pointer">
        <component :is="as" v-bind:href="href" class="w-14 shrink-0 grow-0 aspect-square">
            <img :src="simulation.thumbnail" class="w-full h-full object-cover rounded-full" />
        </component>
        <component :is="as" v-bind:href="href" class="overflow-hidden grow">
            <div class="text-sm font-semibold truncate">{{ simulation.impersonator.name }}</div>
            <p class="text-sm overflow-hidden [-webkit-box-orient:vertical] [display:-webkit-box] [-webkit-line-clamp:2]">
                {{ simulation.scenario.description.length > 110 ? simulation.scenario.description.substring(0, 110) + ' ...' : simulation.scenario.description }}
            </p>
            <div class="footer text-xs text-muted">
                <slot name="author" v-bind="{ author: simulation.author}">
                    <span>by {{ simulation.author.name }}</span>
                </slot>
            </div>
        </component>
        <div>
            <slot name="actions"></slot>
        </div>
    </div>
</template>
